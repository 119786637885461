import * as React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

const Index: React.FC = () => {
  const router = useRouter();

  router.push('/video/category');

  return (
    <div>
      <Head>
        <title>Delmo</title>
      </Head>
    </div>
  );
};

export default Index;
